/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.digitaltrends.com/computing/asus-rog-ally-vs-steam-deck/"
  }, "썸네일 원본"), " by Jacob Roach / Digital Trends"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "초기 UMPC들"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.thekurzweillibrary.com/microsoft-origami-project-prototype"
  }, "MS의 오리가미 프로젝트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.aving.net/news/articleView.html?idxno=6916"
  }, "후지쯔 P1510(이건 좀 애매하지만..)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Sony_Vaio_UX_Micro_PC"
  }, "소니 UX 시리즈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opensea.kr/wibrain-%eb%a6%ac%eb%88%85%ec%8a%a4-umpc-b1l-%ec%b6%9c%ec%8b%9c/"
  }, "국내는 삼성 Q1, 와이브레인의 B1 시리즈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lazion.com/9"
  }, "모디아")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "스팀덱의 등장"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opensea.kr/%ea%b3%bc%ec%97%b0-%ec%9d%b4%eb%b2%88%ec%97%94-%ec%84%b1%ea%b3%b5%ed%95%a0-%ec%88%98-%ec%9e%88%ec%9d%84%ea%b9%8c-%eb%b0%b8%eb%b8%8c%ec%9d%98-%ed%9c%b4%eb%8c%80%ec%9a%a9-%ec%bd%98%ec%86%94-steamdeck/"
  }, "스팀덱에 대한 우려와 기대(초반)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/RDNA_2"
  }, "AMD RDNA2")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://coolenjoy.net/bbs/38/4188062"
  }, "AYA NEO AIR Plus")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/ValveSoftware/Proton"
  }, "Proton 프로젝트"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.hada.io/topic?id=12234"
  }, "리눅스보다 Windows에서 게임이 더 느리다고?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.protondb.com/"
  }, "ProtonDB")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amd.com/ko/products/apu/amd-ryzen-7-7840u"
  }, "AMD 7840U")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.steamdeck.com/ko/"
  }, "스팀덱 OLED")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
